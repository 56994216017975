import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register, login, resetPasswordRequest } from './api';
import './AuthComponent.css';
import { useLocation } from 'react-router-dom';

const AuthComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    // const [isRegistered, setIsRegistered] = useState(false);
    const [message, setMessage] = useState('');
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    // const [canAgree, setCanAgree] = useState(false);
    const [authStatus, setAuthStatus] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    // const modalContentRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation(); // useLocation フックを利用

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get('referral_code');
        const message = params.get('message');
        const authStatus = params.get('status');
        setReferralCode(referralCode);
        if (referralCode) {
            setIsRegister(true); // リファラルコードがあれば登録モードに切り替え
        }
        setAuthStatus(authStatus);
        if (message) {
            setMessage(message);
        }
    }, [location]);

    // useEffect(() => {
    //     if (modalContentRef.current) {
    //         const { scrollHeight, clientHeight } = modalContentRef.current;
    //         if (scrollHeight <= clientHeight) {
    //             setCanAgree(true);
    //         } else {
    //             modalContentRef.current.addEventListener('scroll', handleScroll);
    //         }
    //     }
    //     return () => {
    //         if (modalContentRef.current) {
    //             modalContentRef.current.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, [isModalOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // パスワードの強度チェック: 英字と数字を含むことを必須とする
        const passwordStrengthCheck = (password) => {
            const minLength = 8; // 最低文字数
            const hasLetter = /[a-zA-Z]/.test(password); // 大文字または小文字
            const hasNumber = /[0-9]/.test(password);
    
            return (
                password.length >= minLength &&
                hasLetter &&
                hasNumber
            );
        };
    
        if (isRegister && password !== confirmPassword) {
            setMessage('パスワードが一致しません');
            return;
        }
    
        if (isRegister && !passwordStrengthCheck(password)) {
            setMessage('パスワードは最低8文字で、英字と数字を含む必要があります');
            return;
        }
    
        try {
            let responseData;
    
            if (isRegister) {
                responseData = await register(username, email, password, referralCode);
            } else {
                responseData = await login(email, password);
            }
    
            if (responseData.redirect) {
                window.location.href = responseData.redirect; // リダイレクト
                return;
            }
    
            handleResponse(isRegister, responseData);
    
        } catch (error) {
            const errorMsg = error.response?.data?.message || 'エラーが発生しました。もう一度お試しください。';
            setMessage(errorMsg);
        }
    };    

    const handleResponse = (isRegister, responseData) => {
        if (isRegister) {
            // setIsRegistered(true);
            setAuthStatus('success');
            setMessage('認証メールを送信しました。メールを確認して認証を完了してください。');
        } else if (!isRegister && responseData.status === 202) {
            // setIsRegistered(true);
            setAuthStatus('danger');
            setMessage('メール認証が未完了です。メールを確認して認証を完了してください。');
        } else if (!isRegister && responseData.status === 200) {
            navigate('/');
        }
    };

    // const handleScroll = () => {
    //     if (modalContentRef.current) {
    //         const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    //         if (scrollTop + clientHeight === scrollHeight) {
    //             setCanAgree(true);
    //         }
    //     }
    // };

    const handleForgotPasswordRequest = async (e) => {
        e.preventDefault();
        try {
            await resetPasswordRequest(email);
            setMessage('メールアドレスにパスワード変更用のリンクを送信しました。');
        } catch (error) {
            setMessage('入力されたメールアドレスは見つかりませんでした。');
        }
    };

    const handleCheckboxChange = (event) => {
        setIsAgreed(event.target.checked);
    };

    return (
        <div className="auth-container">
            <div className="auth-logo-container">
                <div className="auth-logo">
                    <img src="logo.svg" alt="imavo Logo" />
                </div>
            </div>
            <div className="auth-form-container">
                {isForgotPassword ? (
                    <>
                        <h2>パスワードを忘れた方</h2>
                        <form onSubmit={handleForgotPasswordRequest}>
                            <label className="auth-label">メールアドレス</label>
                            <input
                                type="email"
                                placeholder="abc@imavo.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="auth-input"
                                required
                            />
                            <button type="submit" className="auth-button">送信</button>
                        </form>
                        <button onClick={() => setIsForgotPassword(false)} className="auth-switch-button">
                            ログインに戻る
                        </button>
                    </>
                ) : (
                    <>
                        <h2>imavoに{isRegister ? '新規登録する' : 'ログインする'}</h2>
                        <form onSubmit={handleSubmit} className="auth-form">
                            {isRegister && (
                                <>
                                    <label className="auth-label">ユーザーネーム</label>
                                    <input
                                        type="text"
                                        placeholder="バーチャルダミーヘッド"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="auth-input"
                                    />
                                </>
                            )}
                            <label className="auth-label">メールアドレス</label>
                            <input
                                type="email"
                                placeholder="abc@imavo.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="auth-input"
                            />
                            <label className="auth-label">パスワード</label>
                            <input
                                type="password"
                                placeholder="******"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="auth-input"
                            />
                            {isRegister && (
                                <>
                                    <label className="auth-label">パスワード（再入力）</label>
                                    <input
                                        type="password"
                                        placeholder="******"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="auth-input"
                                    />
                                </>
                            )}
                            {isRegister && (
                                <>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="terms-checkbox"
                                            checked={isAgreed}
                                            onChange={handleCheckboxChange}
                                        />
                                        <a href='https://imavo.notion.site/imavo-12a02bc87cb780d28a54e64e1f48556e'>利用規約</a>に同意する
                                    </label>
                                </>
                            )}
                            <button type="submit" className="auth-button" disabled={isRegister && !isAgreed}>
                                {isRegister ? '登録' : 'ログイン'}
                            </button>
                        </form>
                        <p>
                            <button onClick={() => setIsRegister(!isRegister)} className="auth-switch-button">
                                {isRegister ? 'ログインはこちら' : '新規登録はこちら'}
                            </button>
                        </p>
                        <p>
                            <button onClick={() => setIsForgotPassword(true)} className="auth-switch-button">
                                パスワードを忘れた方
                            </button>
                        </p>
                    </>
                )}
                {message && <p className={`auth-message auth-message-${authStatus}`}>{message}</p>}
            </div>
        </div>
    );
};

export default AuthComponent;
