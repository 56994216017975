import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

export const register = async (username, email, password, referralCode) => {
    try {
        const response = await instance.post(`/register`, {
            username: username,
            email: email,
            password: password,
            referralCode: referralCode
        });
        return response.data;
    } catch (error) {
        console.error('登録に失敗しました。もう一度やり直してください。', error);
        throw error;
    }
};

// ログイン関数
export const login = async (email, password) => {
    try {
        const response = await instance.post(`/login`, {
            email: email,
            password: password
        });
        return response.data;
    } catch (error) {
        console.error('ログインに失敗しました。もう一度やり直してください。', error);
        throw error;
    }
};

export const logout = () => instance.post('/logout');
export const getCurrentUser = () => instance.get('/current_user');
export const checkSession = () => instance.get('/check_session');
export const getAudio = (filename) => instance.get(`/get_audio/${filename}`);

export const createCustomerPortal = async () => {
    try {
        const response = await instance.post('/create-customer-portal-session');
        const { url }  = response.data;
        if (url) {
            window.location.href = url;
        }
    } catch (error) {
        console.error('Error creating customer portal session:', error);
        throw error;
    }
};

export const createCheckoutSession = async () => {
    try {
        const response = await instance.post('/create-checkout-session');
        return response.data;
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};

export const getMembershipStatus = async () => {
    try {
        const response = await instance.get('/membership-status');
        return response.data;
    } catch (error) {
        console.error('Error fetching membership status:', error);
        throw error;
    }
};

// 単発購入用セッション作成の関数を追加
export const createSinglePaymentSession = async () => {
    try {
        const response = await instance.post('/create-single-payment-session');
        return response.data;
    } catch (error) {
        console.error('Error creating single payment session:', error);
        throw error;
    }
};

// シリアルコードを使用するための関数を追加
export const execSerialCode = async (serialCode) => {
    try {
        const response = await instance.post('/use-serial-code', { serialCode });
        return response.data;
    } catch (error) {
        console.error('Error using serial code:', error);
        throw error;
    }
};

// 会員資格の更新を行うための関数を追加
export const updateMembershipStatus = async (membershipType) => {
    try {
        const response = await instance.post('/update-membership-status', { membershipType });
        return response.data;
    } catch (error) {
        console.error('Error updating membership status:', error);
        throw error;
    }
};

// シリアルコードの有効期限をチェックするための関数を追加
export const checkSerialCodeExpiration = async () => {
    try {
        const response = await instance.get('/check-serial-code-expiration');
        return response.data.expired; // `expired` はバックエンドが返す真偽値と仮定
    } catch (error) {
        console.error('Error checking serial code expiration:', error);
        throw error;
    }
};

export const getUserDetails = async () => {
    try {
        const response = await instance.get('/user-details');
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

export const changePassword = async (currentPassword, newPassword) => {
    try {
        const response = await instance.post('/change-password', {
            current_password: currentPassword,
            new_password: newPassword,
        });
        return response.data;
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};

// メールアドレス変更リクエストを送信する関数
export const changeEmail = async (newEmail) => {
    try {
        const response = await instance.post('/change-email', { newEmail });
        return response.data;
    } catch (error) {
        console.error('Error requesting email change:', error);
        throw error;
    }
};

export const resetPasswordRequest = async (email) => {
    try {
        const response = await instance.post('/reset-password-request', { email });
        return response.data;
    } catch (error) {
        console.error('メールの送信に失敗しました。もう一度やり直してください。', error);
        throw error;
    }
}

export const resetPassword = async (token, newPassword) => {
    try {
        const response = await instance.post(`/reset-password/${token}`, { new_password: newPassword });
        return response.data;
    } catch (error) {
        console.error('パスワードのリセットに失敗しました。もう一度やり直してください。', error);
        throw error;
    }
}

export default instance;
