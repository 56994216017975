import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import SelectableBall from './SelectableBall';
import MyPage from './MyPage';
import FriendCode from './FriendCode';
import './Live.css';
import { useNavigate } from 'react-router-dom';
import { decode as base64Decode } from 'base64-arraybuffer';
import CryptoJS from 'crypto-js';
import {
    getUserDetails,
    getAudio,
    checkSession,
    logout,
} from './api';

const MicMonitor = () => {
    const [audioContext, setAudioContext] = useState(null);
    const [micStream, setMicStream] = useState(null);
    // const [audioSource, setAudioSource] = useState(null);
    const gainNode = useRef(null);
    const [convolverNode, setConvolverNode] = useState(null);
    const [splitterNode, setSplitterNode] = useState(null);
    const [impulseResponses, setImpulseResponses] = useState(Array(9).fill(null)); // 配列で管理
    // const [status, setStatus] = useState("マイクはオフです。");
    const [isStreaming, setIsStreaming] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // const [membershipMessage, setMembershipMessage] = useState('');
    const expirationRef = useRef('');
    // const [message, setMessage] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [userData, setUserData] = useState(''); // ユーザー名のステートを追加
    const [showMyPage, setShowMyPage] = useState(false);
    const [showFriendCode, setShowFriendCode] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userData = await getUserDetails();
                if (userData) {
                    setUserData(userData);
                    expirationRef.current = userData.temporary_expiration;
                    if (userData.referral_code) {
                        setReferralCode(userData.referral_code); // Set referral_code
                    }
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        const handleIntervalActions = async () => {
            try {
                // セッション状態を確認
                const response = await checkSession();

                // セッションが無効な場合、ログインページにリダイレクト
                if (!response.data.logged_in) {
                    window.location.href = '/login';
                    return; // 他の処理を行わずに終了
                }

                // 他の処理を続行
                await fetchUserDetails();

            } catch (error) {
                console.error('Error in handleIntervalActions:', error);
                // 必要に応じてエラーメッセージを表示
            }
        };

        handleIntervalActions();
    }, []);


    useEffect(() => {
        const loadImpulseResponses = async () => {
            try {
                const impulseFiles = [
                    'data_30_73.wav',
                    'data_30_48.wav',
                    'data_30_37.wav',
                    'data_30_23.wav',
                    'data_30_19.wav',
                    'data_30_15.wav',
                    'data_30_1.wav',
                    'data_30_62.wav',
                    'data_30_74.wav'
                ];

                const loadedResponses = await Promise.all(
                    impulseFiles.map(async file => {
                        return await loadAudio(file, audioContext);
                    })
                );

                setImpulseResponses(loadedResponses);
            } catch (err) {
                console.error('インパルス応答の読み込みに失敗しました:', err);
            }
        };

        if (audioContext) {
            loadImpulseResponses();
        }
    }, [audioContext]);

    useEffect(() => {
        const requestMicrophoneAccess = async () => {
            try {
                // マイクへのアクセスを要求
                await navigator.mediaDevices.getUserMedia({ audio: true });

                // オーディオデバイスを取得
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');

                setDevices(audioDevices);
            } catch (err) {
                if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                    console.error('マイクへのアクセスが拒否されました。');
                } else {
                    console.error('ストリームの取得に失敗しました:', err);
                }
            }
        };

        requestMicrophoneAccess();
    }, []);

    // Uint8ArrayをCryptoJS.lib.WordArrayに変換する関数
    const arrayBufferToWordArray = (ab) => {
        const i8a = new Uint8Array(ab);
        const a = [];
        for (let i = 0; i < i8a.length; i += 4) {
            a.push(
                (i8a[i] << 24) |
                (i8a[i + 1] << 16) |
                (i8a[i + 2] << 8) |
                (i8a[i + 3])
            );
        }
        return CryptoJS.lib.WordArray.create(a, i8a.length);
    };

    const loadAudio = async (fileName, context) => {
        try {
            const response = await getAudio(fileName);
            const encodedAudio = response.data.audio;
            const encodedKey = response.data.key;

            // Base64デコード
            const encryptedBytes = base64Decode(encodedAudio);
            const keyBytes = base64Decode(encodedKey);

            // AES復号化のためのキーとIVを設定
            const key = arrayBufferToWordArray(keyBytes);
            const iv = arrayBufferToWordArray(encryptedBytes.slice(0, 16));
            const encryptedData = arrayBufferToWordArray(encryptedBytes.slice(16));

            const decryptedData = CryptoJS.AES.decrypt(
                { ciphertext: encryptedData },
                key,
                { iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding }
            );

            // 復号化されたデータをUint8Arrayに変換
            const decryptedBytes = new Uint8Array(decryptedData.sigBytes);
            for (let i = 0; i < decryptedData.sigBytes; i++) {
                decryptedBytes[i] = (decryptedData.words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
            }

            return await context.decodeAudioData(decryptedBytes.buffer);
        } catch (error) {
            throw error;
        }
    };

    const handleDeviceChange = (event) => {
        const deviceId = event.target.value;
        setSelectedDeviceId(deviceId);

        if (!audioContext) {
            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            setAudioContext(newAudioContext);
        }
    };

    // ゲイン調整のスライドバーの変更時に呼び出される関数
    const handleGainChange = (event) => {
        if (gainNode.current) {
            const newValue = parseFloat(event.target.value);
            gainNode.current.gain.value = newValue;  // useRefで参照しているgainNodeを更新
            console.log('Gain value set to:', newValue);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Error logging out', error);
        }
    };

    const startMonitoring = async () => {
        if (!audioContext) {
            console.error('AudioContextが作成されていません。');
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: selectedDeviceId,
                    echoCancellation: false,
                    noiseSuppression: false,
                    autoGainControl: false
                }
            });
            setMicStream(stream);

            const source = audioContext.createMediaStreamSource(stream);
            // setAudioSource(source);

            // ステレオ信号を2チャンネルに分割
            const splitter = audioContext.createChannelSplitter(2);
            setSplitterNode(splitter);

            gainNode.current = audioContext.createGain();
            gainNode.current.gain.value = 1;

            const convolver = audioContext.createConvolver();
            convolver.normalize = false;
            setConvolverNode(convolver);

            // 初期インパルス応答を設定
            convolver.buffer = impulseResponses[4];

            // 音声ソースをConvolverNodeに接続し、ConvolverNodeをゲインノードに接続
            source.connect(splitter);
            splitter.connect(convolver, 0); // ステレオ入力デバイス用に明示的に1chを指定
            convolver.connect(gainNode.current);
            gainNode.current.connect(audioContext.destination);

            // setStatus("マイクはオンです。");
            setIsStreaming(true);
        } catch (err) {
            console.error('マイクの取得に失敗しました:', err);
            // setStatus("マイクの取得に失敗しました。");
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const stopMonitoring = () => {
        console.log('micstream', micStream);
        if (micStream) {
            micStream.getTracks().forEach(track => track.stop());
            setMicStream(null);
            // setAudioSource(null);
            gainNode.current = null;
            setConvolverNode(null);
            setIsStreaming(false);
            // setStatus("マイクはオフです。");
            setIsButtonDisabled(false);
            console.log('stopしました', isStreaming);
        }
    };

    const toggleMonitoring = () => {
        setIsButtonDisabled(true);
        setIsPassThru(false);
        if (isStreaming) {
            stopMonitoring();
        } else {
            startMonitoring();
        }
    };

    const [isPassThru, setIsPassThru] = useState(false);

    const togglePassThru = () => {
        if (isStreaming) {
            setIsPassThru(prevState => !prevState);
            if (isPassThru) {
                // パススルーをオフにする
                splitterNode.disconnect(gainNode.current);
                splitterNode.connect(convolverNode);
                convolverNode.connect(gainNode.current);
            } else {
                // パススルーをオンにする
                splitterNode.disconnect(convolverNode);
                splitterNode.connect(gainNode.current);
            }
        }
    };

    const toggleMyPage = () => {
        setShowMyPage(!showMyPage);
    }

    const toggleFriendCode = () => {
        setShowFriendCode(!showFriendCode);
    }

    // インパルス応答の切り替え
    const switchImpulseResponse = (index) => {
        if (convolverNode && impulseResponses[index]) {
            convolverNode.buffer = impulseResponses[index];
        }
    };

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
                />
            </Helmet>
            <div className="live-container">
                <aside className="sidebar">
                    <div className="user-info">
                        <i className="fas fa-user-circle user-icon"></i>
                        <span className="username">{userData ? userData.username : 'ユーザー名'}さん</span>
                    </div>
                    <div className="logo">
                        <img src="logo.svg" alt="imavo Logo" />
                    </div>
                    <hr className="logo-divider" />

                    <div className="campaign-bubble">
                        友達紹介キャンペーン実施中！
                    </div>

                    <ul className="menu">
                        <li><a href="#" onClick={toggleFriendCode}><i class="fa-solid fa-user-group"></i> 友達紹介コード</a></li>
                        <li><a href="#" onClick={toggleMyPage}><i class="fa-solid fa-gear"></i> 設定</a></li>
                        <li><a href="https://imavo.notion.site/ImaVo-LIVE-13102bc87cb780eab370d030fd71eedd?pvs=4" target="_black"><i className="fas fa-question-circle"></i> ヘルプ</a></li>
                        <li><a href="https://virtual-dummy-head.notion.site/13102bc87cb780d09ecbe60a6decfef7?pvs=105" target="_black"><i className="fas fa-envelope"></i> お問い合わせ</a></li>
                        {/* <li><a href="#"><i className="fab fa-discord"></i> Discord</a></li> */}
                        <li><a href="#" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> ログアウト</a></li>
                    </ul>

                </aside>
                <div className="content">
                    <div className="live-column-container">
                        <div className="live-select-container">
                            <p className="label-live-mic">🎙️ マイク設定</p>
                            <select className="mic-select" onChange={handleDeviceChange} disabled={isStreaming} value={selectedDeviceId}>
                                <option value="">▼音声入力デバイスを選択してください</option>
                                {devices.map(device => (
                                    <option key={device.deviceId} value={device.deviceId}>
                                        {device.label || `Device ${device.deviceId}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="live-control-item">
                            <p className="label-live-gain">🔊 ゲイン調整</p>
                            <input
                                type="range"
                                min="0"
                                max="2"
                                step="0.01"
                                defaultValue="1"
                                onChange={handleGainChange}
                                className="live-slider"
                            />
                        </div>
                        <div className="live-button-container">
                            <button
                                onClick={toggleMonitoring}
                                className={`live-button ${isStreaming ? "enable" : ""}`}
                                disabled={!selectedDeviceId || isButtonDisabled}
                            >
                                {isStreaming ? (
                                    <span>⏹ OFF</span>
                                ) : (
                                    <span>● 3Dボイス ON</span>
                                )}
                            </button>
                        </div>

                        <div className="live-button-container">
                            <button
                                onClick={togglePassThru}
                                className={`live-thru-button ${!isStreaming ? "" : isPassThru ? "blink-green" : "blink-red"}`}
                                disabled={!isStreaming}
                            >
                                {isPassThru ? (
                                    <>
                                        モノラルボイス再生中<br />
                                        <span className="small-text">（3D⇔モノラル切替ボタン）</span>
                                    </>
                                ) : (
                                    <>
                                        3Dボイス再生中<br />
                                        <span className="small-text">（3D⇔モノラル切替ボタン）</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="live-pan-container">
                        <SelectableBall
                            switchImpulseResponse={switchImpulseResponse}
                        />
                        <div className="live-tips-container">
                            <img src="live_tips.svg" alt="Live Tips" className="live-tips-image" />
                        </div>
                    </div>
                    {showFriendCode && (
                        <div className="overlay" onClick={toggleFriendCode}>
                            <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                                <button className="close-icon-btn" onClick={toggleFriendCode}>
                                    <i className="fas fa-times"></i>
                                </button>
                                <FriendCode
                                    userData={userData}
                                    referralCode={referralCode}
                                />
                            </div>
                        </div>
                    )}
                    {showMyPage && (
                        <div className="overlay" onClick={toggleMyPage}>
                            <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                                <button className="close-icon-btn" onClick={toggleMyPage}>
                                    <i className="fas fa-times"></i>
                                </button>
                                <MyPage
                                    userData={userData}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MicMonitor;