import React, { useState } from 'react';
import { changePassword, changeEmail } from './api';
import './MyPage.css';

const MyPage = ({ userData }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const [passwordMessage, setPasswordMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const handlePasswordChange = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setPasswordMessage('新しいパスワードと確認用パスワードが一致しません。');
            return;
        }

        try {
            await changePassword(currentPassword, newPassword);
            setPasswordMessage('パスワードが正しく変更されました。');
        } catch (error) {
            setPasswordMessage('入力されたパスワードは使用できません。');
        }
    };

    const handleEmailChange = async (event) => {
        event.preventDefault();

        try {
            await changeEmail(newEmail);
            setEmailMessage('新しいメールアドレスに認証メールを送信しました。メールを確認して認証を完了してください。');
        } catch (error) {
            setEmailMessage('入力されたメールアドレスは使用できません。');
        }
    };

    return (
        <div className="modal-content">
            <h1>設定</h1>

            {/* メールアドレス変更フォーム */}
            <div className='email-change'>
                <h2>メールアドレスを変更する</h2>
                <div className="float-container content-left-align">
                    <p>現在のメールアドレス: {userData.email}</p>
                </div>
                <form onSubmit={handleEmailChange} className="mypage-form">
                    <input
                        type="email"
                        placeholder="新しいメールアドレス"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        required
                        className='auth-input'
                    />
                    <button type="submit" className="auth-button">メールを変更する</button>
                </form>
                {/* Display message related to email update */}
                {emailMessage && <p>{emailMessage}</p>}
            </div>

            {/* パスワード変更フォーム */}
            <div className='password-change'>
                <h2>パスワードを変更する</h2>
                <form onSubmit={handlePasswordChange} className="mypage-form">
                    <input
                        type="password"
                        placeholder="現在のパスワード"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className='auth-input'
                    />
                    <input
                        type="password"
                        placeholder="新しいパスワード"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className='auth-input'
                    />
                    <input
                        type="password"
                        placeholder="新しいパスワード（確認）"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                        className='auth-input'
                    />
                    <button type="submit" className="auth-button">パスワードを変更する</button>
                </form>
                {/* Display message related to password update */}
                {passwordMessage && <p>{passwordMessage}</p>}
            </div>
        </div>
    );
};

export default MyPage;
