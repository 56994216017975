import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthComponent from './AuthComponent';
import PrivateRoute from './PrivateRoute';
import MicMonitor from './MicMonitor';
import PasswordResetForm from './PasswordResetForm';
import './App.css';
import BeforeOpen from './BeforeOpen';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<AuthComponent />} />
                <Route path="/" element={<PrivateRoute element={MicMonitor} />} />
                {/* <Route path="/" element={<PrivateRoute element={BeforeOpen} />} /> */}
                <Route path="/reset-password/:token" element={<PasswordResetForm />} /> 
            </Routes>
        </Router>
    );
}

export default App;
