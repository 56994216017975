import React, { useState } from 'react';
import './MyPage.css';

const MyPage = ({ userData, referralCode }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const referralUrl = `${baseUrl}/login?referral_code=${referralCode}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralUrl)
            .then(() => {
                setCopySuccess('リンクをコピーしました');
                // 数秒後にメッセージを消す
                setTimeout(() => setCopySuccess(''), 3000);
            })
            .catch(err => {
                console.error('コピーに失敗しました: ', err);
            });
    };

    return (
        <div className="modal-content">
            <img src="cp.png" alt="Campaign Banner" className='cp-image' />
            <div className='referralCode'>
                <h2>友達紹介コード</h2>
                {/* リンクを表示するテキストフォーム */}
                <input type='text' value={referralUrl} readOnly className='auth-input' />
                <button onClick={copyToClipboard} className="copy-button">
                    コピー
                </button>
                {/* コピー成功メッセージ */}
                {copySuccess && <div className="success-message">{copySuccess}</div>}
            </div>
        </div>
    );
};

export default MyPage;
