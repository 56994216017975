import React, { useState, useEffect } from 'react';

const SelectableBall = ({ switchImpulseResponse }) => {
    const ballRadius = 20;
    const smallCircleRadius = 40;
    const clickableRadius = 120;
    const [pinkBallPosition, setPinkBallPosition] = useState(null);
    const [selectedCircleIndex, setSelectedCircleIndex] = useState(4);

    // 極座標からデカルト座標への変換関数
    const polarToCartesian = (radius, azimuth) => {
        const radians = (azimuth + 90) * (Math.PI / 180);
        return {
            x: radius * Math.cos(radians),
            y: -radius * Math.sin(radians),
        };
    };

    // クリック可能な円の定義（極座標）
    const circles = [
      { radius: smallCircleRadius / 2 + 20, azimuth: 90 }, // Circle 1
      { radius: clickableRadius, azimuth: 145 },           // Circle 2
      { radius: clickableRadius, azimuth: 90 },            // Circle 3
      { radius: clickableRadius, azimuth: 40 },            // Circle 4
      { radius: clickableRadius, azimuth: 0 },             // Circle 5 (Default)
      { radius: clickableRadius, azimuth: 320 },           // Circle 6
      { radius: clickableRadius, azimuth: 270 },           // Circle 7
      { radius: clickableRadius, azimuth: 215 },           // Circle 8
      { radius: smallCircleRadius / 2 + 20, azimuth: 270 } // Circle 9
    ];

    useEffect(() => {
        // デフォルトで正面の円を選択
        handleCircleClick(circles[4], 4);
    }, []);

    const handleCircleClick = (circle, index) => {
        const { x, y } = polarToCartesian(circle.radius, circle.azimuth);
        switchImpulseResponse(index);
        setPinkBallPosition({ x, y });
        setSelectedCircleIndex(index);
    };

    const handleContainerClick = (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left - rect.width / 2;
        const y = event.clientY - rect.top - rect.height / 2;

        console.log(`Clicked at: x=${x}, y=${y}`);

        for (let i = 0; i < circles.length; i++) {
            const circle = circles[i];
            const { x: circleX, y: circleY } = polarToCartesian(circle.radius, circle.azimuth);
            if (Math.sqrt((x - circleX) ** 2 + (y - circleY) ** 2) <= ballRadius) {
                handleCircleClick(circle, i);
                return;
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            const index = parseInt(event.key, 10) - 1;
            if (index >= 0 && index < circles.length) {
                handleCircleClick(circles[index], index);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [circles]);

    const renderSmallCircle = () => {
        const enlargedRadius = smallCircleRadius * 1.2;
        return (
            <img
                src="head.svg"
                alt="Head"
                style={{
                    position: 'absolute',
                    left: `calc(50% - ${enlargedRadius}px)`,
                    top: `calc(50% - ${enlargedRadius}px - 5px)`,
                    width: `${enlargedRadius * 2}px`,
                    height: `${enlargedRadius * 2}px`,
                    borderRadius: '50%',
                    zIndex: 1,
                }}
            />
        );
    };

    const renderMarkers = () => {
        return circles.map((circle, index) => {
            const { x, y } = polarToCartesian(circle.radius, circle.azimuth);
            return (
                <div
                    key={index}
                    style={{
                        position: 'absolute',
                        left: `calc(50% + ${x}px - ${ballRadius}px)`,
                        top: `calc(50% + ${y}px - ${ballRadius}px)`,
                        width: `${ballRadius * 2}px`,
                        height: `${ballRadius * 2}px`,
                        borderRadius: '50%',
                        border: '2px dotted #ff00ff',
                        backgroundColor: 'rgba(144, 144, 193, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontSize: '12pt',
                        cursor: 'pointer',
                        zIndex: 2,
                    }}
                    onClick={() => handleCircleClick(circle, index)}
                >
                    {index + 1}
                </div>
            );
        });
    };

    return (
        <div className="circle-container" onClick={handleContainerClick}>
            <div className="label top-label">Front<br />（正面）</div>
            <div className="label bottom-label">Back<br />（背後）</div>
            <div className="label left-label">Left<br />（左）</div>
            <div className="label right-label">Right<br />（右）</div>
            <div className="inner-circle">
                <div className="crosshair"></div>
                {renderSmallCircle()}
                {renderMarkers()}
                {pinkBallPosition && (
                    <div
                        className="draggable-circle"
                        style={{
                            left: `calc(50% + ${pinkBallPosition.x}px - ${ballRadius}px)`,
                            top: `calc(50% + ${pinkBallPosition.y}px - ${ballRadius}px)`,
                            width: `${ballRadius * 2}px`,
                            height: `${ballRadius * 2}px`,
                            borderRadius: '50%',
                            border: '2px solid #333333',
                            backgroundColor: '#ff00ff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#333333',
                            fontSize: '16pt',
                            fontWeight: 'bold',
                            zIndex: 3,
                        }}
                    >
                        {selectedCircleIndex + 1}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectableBall;
