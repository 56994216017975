import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from './api';

const PasswordResetForm = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [authStatus, setAuthStatus] = useState(null);
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setAuthStatus('danger');
            setMessage('パスワードが一致しません。もう一度確認してください。');
            return;
        }

        const passwordStrengthCheck = (newPassword) => {
            const minLength = 8; // 最低文字数
            const hasLetter = /[a-zA-Z]/.test(newPassword); // 大文字または小文字
            const hasNumber = /[0-9]/.test(newPassword);
    
            return (
                newPassword.length >= minLength &&
                hasLetter &&
                hasNumber
            );
        };
        
        if (!passwordStrengthCheck(newPassword)) {
            setMessage('パスワードは最低8文字で、英字と数字を含む必要があります');
            return;
        }

        try {
            const data = await resetPassword(token, newPassword);
            setAuthStatus('success');
            setMessage(data.message);

        } catch (error) {
            console.error('Error:', error);
            setAuthStatus('danger');
            setMessage(error.response?.data?.error || 'エラーが発生しました。');
        }
    };

    return (
        <div className="auth-container">
            <div className='auth-form-container'>
                <h2>パスワードリセット</h2>
                
                {authStatus !== 'success' && (
                    <form onSubmit={handleSubmit} className='auth-form'>
                        <input
                            type="password"
                            placeholder="新しいパスワードを入力"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className='auth-input'
                        />
                        <input
                            type="password"
                            placeholder="パスワードをもう一度入力"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className='auth-input'
                        />
                        <button type="submit" className='auth-button'>パスワードを変更する</button>
                    </form>
                )}
                
                {authStatus === 'success' && (
                    <div>
                        <button onClick={() => navigate('/login')} className='auth-button'>
                            ログイン画面に移動
                        </button>
                    </div>
                )}
                
                {message && <p className={`auth-message auth-message-${authStatus}`}>{message}</p>}

            </div>
        </div>
    );
};

export default PasswordResetForm;
